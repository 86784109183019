body {
  margin: 0;
  padding: 0;
  font-family: 'Darker Grotesque', sans-serif;
  top: 0px !important;
  color: #282827;
}

p, a, label, input, select, button {
    font-family: 'Darker Grotesque', sans-serif;
}
